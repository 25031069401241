import { unwrapResult } from '@reduxjs/toolkit'
import {
  Col,
  Modal,
  notification,
  Row,
  Space,
  Tabs,
  Tooltip,
  Button,
  Popover,
  Table,
} from 'antd'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../app/store'
import {
  AppointmentIcon,
  BackOutlined, CAIcon,UNCAIcon,
  EditOutlined,
  FemaleFilled,
  MaleFilled,
  OweMoneyIcon,
} from '../../compnents/icons/Icons'
import { TabBar } from '../../compnents/widgets/TabBar'
import {
  selectFollowUp,
  traceRoute,
  untraceRoute,
} from '../../layouts/layoutSlice'
import { Patient } from '../../models/patient'
import { Gender, getGenderName } from '../../models/user'
import { ThemeContext } from '../../theme/ThemeContext'
import { getAge } from '../../utils/StringUtils'
import { PatientModal } from '../patient/modals/PatientModal'
import { getPatientDetail } from '../patient/patientSlice'
import { Diagnosis } from './diagnosis/Diagnosis'
import {
  getTreatments,
  isEditTreatmentAsync, removeCASign,
  resetDiagnosis,
  selectRegistration,
  selectTreatment, treatmentCASign,
} from './diagnosis/diagnosisSlice'
import { RecipeListEditor } from './editor/RecipeListEditor'
import {
  getCutTab,
  getTab,
  getTreatmentNotice,
  resetRecipeList,
  setPatientInfo,
  setRecipeEditorTreatment,
} from './editor/recipeListEditorSlice'
import styles from './Treatment.module.css'
import { useQuery } from '../../utils/HttpUtils'
import {
  getDiagnosesKey,
  getDiagnosesName,
  getDiagnosesState,
} from '../../models/template'
import {
  CloseCircleOutlined,
  ImportOutlined,
  InfoCircleTwoTone,
} from '@ant-design/icons'
import { TCMCases } from './tcmCases/TcmCases'
import { OralCases } from './oralCases/OralCases'
import { TreatmentNotice } from '../../models/treatment'
import { resetOralCase, selectTreatmentO } from './oralCases/oralCasesSlice'
import { resetTcmCase, selectTreatmentT } from './tcmCases/tcmCasesSlice'
import {
  reSetRegistration,
  selectDetailVisible,
  selectPatientModalVisible,
  selectTreatmentLeftPage,
  setDetailVisible,
  setMedicalRecordVisible,
  setPatientModalVisible,
  treatmentRegistration,
} from './treatmentSlice'
import { selectBooleanSetting, setSettings } from '../settings/settingsSlice'
import { Settings } from '../../models/setting'
import {
  selectCwcaOptId,
  selectInsuranceArray,
  selectTenantAddress, selectTenantAreaCd, selectTenantCategory,
  selectTenantId, selectTenantName,
  selectUserId,
  selectUserName
} from '../../app/applicationSlice'
import { BeautyClinic } from './beautyClinic/beautyClinic'
import { Surgical } from './surgical/Surgical'
import { SurgicalWord } from './surgicalWord/SurgicalWord'
import { selectTreatmentB } from './beautyClinic/beautyClinicSlice'
import {
  getAcceptsType,
  searchMedicalAsync,
  searchPsnSumAsync,
  searchPsnSumForLocalAsync,
  setSearchMedical,
} from '../registration/register/registerSlice'
import { AddFollowUpModal } from '../followUp/addFollowUp'
import { setAddVisible } from '../followUp/followUpPageSlice'
import { oweMoney } from '../payment/list/paymentSlice'
import { useHistory, useLocation } from 'react-router-dom'
import { selectTenant } from '../user/setting/settingSlice'
import { MedicalRecordModal } from './tcmCases/MedicalRecordModal'
import { TreatmentBoxList } from './list/TreatmentBoxList'
import {getUserProfileList, saveCwcaOptId} from '../personalManagement/personalDetail/personalDetailSlice'
import { MedicalOverview } from '../medicalOverview/medicalOverview'
import ybPng from './images/yb.png'
import zfPng from './images/zf.png'
import { setLableId } from '../patient/patientLable/patientlableSlice'
import {
  InvokeCWCAService,
  medicalSendHttp,
  pingTargeHttp,
  strParse,
} from '../../utils/MedicalUtils'
import { otherPatientInsuranceItemParams } from '../../models/payment'
import {selectInsuranceItem, setInsurance} from '../../layouts/insuranceSlice'
import { getYBTypeName } from '../../models/ybType'
import DCWriterControl from "../medicalBeautyTemplate/DCWriterControl";
import {BeautyClinicDC} from "./beautyClinic/beautyClinicDC";
import {
  getSettingDetail,
  selectSettings,
} from '../user/setting/settingSlice'
import {getPrintTemplateSettingDetail} from "../print/printSlice";
import {getTenantDetail} from "../tenant/tenantSlice";
import moment from "moment";
import {DateTimeFormatSimpleOnlyOne} from "../../models/datetime";
export type serverType = 'requestString' | 'response'

type TreatmentTabKey =
  | 'diagnosis'
  | 'editor'
  | 'toothCases'
  | 'tcmCases'
  | 'Surgical'
  | 'BeautyClinic'
  | 'SurgicalWord'
  | 'medicalOverview'
  | undefined

const { TabPane } = Tabs

export const Treatment = (): ReactElement => {
  const insuranceArray = useSelector(selectInsuranceArray)

  const dispatch = useDispatch<RootDispatch>()

  const theme = useContext(ThemeContext)

  const cwcaOptId = useSelector(selectCwcaOptId)

  const tenantId = useSelector(selectTenantId)

  const baseData = useSelector(selectInsuranceItem)

  const [sumVisible, setSumVisible] = useState(false)

  const [sumList, setSumList] = useState<any>()

  const history = useHistory()

  const location = useLocation<any>()

  const [currentTab, setCurrentTab] = useState<TreatmentTabKey>('diagnosis')

  const registration = useSelector(selectRegistration)

  const [patient, setPatient] = useState<Patient | undefined>()

  // const [isPatientModalVisible, setIsPatientModalVisible] = useState(false)

  const isPatientModalVisible = useSelector(selectPatientModalVisible)

  const [notice, setNotice] = useState<TreatmentNotice | undefined>()

  const [isModalVisible, setIsModalVisible] = useState(false)

  const [isAddFollowUpModalVisible, setIsAddFollowUoModalVisible] = useState(
    false
  )

  // const [CASignatureKeyLoading, setCASignatureKeyLoading] = useState(false)

  const [oweMoneyNum, setOweMoneyNum] = useState(0) //是否欠费

  const [owelabel, setoweLabel] = useState<any>([])

  const [appointmentRemark, setAppointmentRemark] = useState('')

  const flagRegistration = useSelector(treatmentRegistration)

  const doNotWarnDoctorMismatch = useSelector(
    selectBooleanSetting(Settings.Treatment.DoNotWarnDoctorMismatch)
  )

  const userId = useSelector(selectUserId)

  const [tabName, setTabName] = useState<any>()

  const [treatmentFlag, setTreatmentFlag] = useState(true)

  const [Tag, setTag] = useState('')

  const [openAdvancedMedical, setOpenAdvancedMedical] = useState(false)

  const [message, setMessage] = useState<any>()

  const [registrations, setRegistration] = useState<any>()

  const [patientInsurance, setPatientInsurance] = useState<any>()

  const [insuranceBean, setInsuranceBean] = useState<any>()

  const [trData, setTrData] = useState<any>()

  const [pingState, setPingState] = useState(false)

  const treatment = useSelector(selectTreatment)

  const treatmentO = useSelector(selectTreatmentO)

  const treatmentT = useSelector(selectTreatmentT)

  const treatmentB = useSelector(selectTreatmentB)

  const showFollowUp = useSelector(selectFollowUp)

  const [searchPsnSumDisabled, setSearchPsnSumDisabled] = useState<boolean>(
    false
  )
  const oweMoneyShow: any = useSelector(selectTenant) //欠费是否显示

  const detailVisible = useSelector(selectDetailVisible)

  const treatmentLeftPage = useSelector(selectTreatmentLeftPage)

  const [preferencesSetFlag, setPreferencesSetFlag] = useState(false) //偏好设置

  const selectKey = location?.state?.backSelectTab

  const [backSelectKey, setBackSelectKey] = useState<any>()

  const [insuranceItem, setInsuranceItem] = useState<any>()

  const userName = useSelector(selectUserName)

  const tenantAddress = useSelector(selectTenantAddress)

  const tenantAreaCd = useSelector(selectTenantAreaCd)

  const tenantName = useSelector(selectTenantName)

  const cycleCode = sessionStorage.getItem('cycleCode')

  const tenantCategory = useSelector(selectTenantCategory)

  useEffect(() => {
    dispatch(getSettingDetail())
  }, [])

  // 保存医保结构体
  useEffect(() => {
    if (insuranceItem && JSON.stringify(insuranceItem) !== '{}') {
      dispatch(
        setInsurance({
          ...insuranceItem,
          userName,
          userId,
          cycleCode,
          tenantCategory,
          tenantAreaCd,
          name: tenantName,
          address: tenantAddress,
          areaCode: tenantAreaCd,
          endTime: moment(insuranceItem.endTime).format(
            DateTimeFormatSimpleOnlyOne
          ),
        })
      )
    }
    return () => {
      dispatch(setInsurance({}))
    }
  }, [insuranceItem])

  useEffect(() => {
    if (insuranceArray.length !== 0) {
      setInsuranceItem(insuranceArray[0])
    }
  }, [insuranceArray])

  useEffect(() => {
    setBackSelectKey(selectKey)
  }, [selectKey])

  useEffect(() => {
    dispatch(setAddVisible(isAddFollowUpModalVisible))
  }, [isAddFollowUpModalVisible])

  const searchMedical = () => {
    registrationId &&
      dispatch(searchMedicalAsync({ registrationId: registrationId }))
  }
  const getPingState = () => {
    pingTargeHttp((call: any) => {
      setPingState(call)
    })
  }
  useEffect(() => {
    if (
      registration &&
      registration.insuranceCode &&
      registration.insuranceCode !== 'ZIFEI'
    ) {
      searchMedical()
      getPingState()
    } else {
      dispatch(setSearchMedical([]))
    }
  }, [registration])

  const isHighLevelTreatment = useSelector(
      selectBooleanSetting(Settings.Treatment.highTreatmentTemplate)
  )


  useEffect(() => {
    // // 获取详情
    // dispatch(getTenantDetail(String(tenantId)))
    //     .then(unwrapResult)
    //     .then((vs: any) => {
    //       const onOFF = vs?.functionList?.filter(
    //           (v: any) => v?.type == 51
    //       )?.[0]?.value
    //       setOpenAdvancedMedical(onOFF)
    //     })
    if(isHighLevelTreatment){
      setOpenAdvancedMedical(true);
    }
  }, [])

  useEffect(() => {
    if (currentTab !== 'editor') {
      dispatch(resetDiagnosis())
      dispatch(resetOralCase())
      dispatch(resetTcmCase())
      dispatch(resetRecipeList())
      dispatch(setRecipeEditorTreatment(undefined))
      // setTreatmentFlag(false);
    }
  }, [currentTab])
  useEffect(() => {
    return () => {
      dispatch(reSetRegistration(undefined))
    }
  }, [])

  useEffect(() => {
    if (flagRegistration) {
      if (
        flagRegistration.registrationDoctorId &&
        flagRegistration.treatmentDoctorId &&
        flagRegistration.registrationDoctorId != '0' &&
        flagRegistration.treatmentDoctorId != '0'
      ) {
        if (
          !doNotWarnDoctorMismatch &&
          flagRegistration.registrationDoctorId !== userId &&
          flagRegistration.treatmentDoctorId !== userId
        ) {
          dispatch(isEditTreatmentAsync())
            .then(unwrapResult)
            .then((res: any) => {
              if (res == 0) {
                Modal.warning({
                  title: '当前系统设定不允许修改其他医生的病历/处方信息！',
                  okText: '确定',
                  onOk: () => {
                    history.go(-1)
                  },
                })
              } else {
                if (!sessionStorage.getItem('SHOWSUREIDDOCTORSELE')) {
                  Modal.confirm({
                    title: '非本医生的患者，是否继续就诊？',
                    onCancel: () => {
                      history.go(-1)
                      // dispatch(
                      //   untraceRoute({
                      //     name: '医生门诊',
                      //     path: '/treatment',
                      //   })
                      // )
                    },
                    onOk: () => {
                      sessionStorage.setItem('SHOWSUREIDDOCTORSELE', '1')
                    },
                  })
                }
              }
            })
        }
      }
    }
  }, [flagRegistration?.registrationDoctorId])

  useEffect(() => {
    if (isModalVisible)
      setTimeout(function () {
        setIsModalVisible(false)
      }, 2000)
  }, [dispatch, isModalVisible])

  useEffect(() => {
    const tabNum = localStorage.getItem('tabNum')
    // localStorage

    if (tabName?.length) {
      if (tabName.slice(0, 4).some((v: any) => v?.flag)) {
        setTreatmentFlag(true)
        if (tabName.find((v: any) => v.flag === 1).length > 1) {
          return setCurrentTab('BeautyClinic')
        } else {
          tabName?.find((v: any) => {
            if (v?.flag === 1) {
              if (location?.state?.key) {
                setCurrentTab(location.state?.key);
              } else {
                return setCurrentTab(getDiagnosesKey(v?.diagnoses) || 'diagnosis')
              }
            }
          })
        }
      } else {
        if (location?.state?.key) {
          setCurrentTab(location.state?.key);
        } else {
          setCurrentTab(getDiagnosesKey(tabName[0].diagnoses) || "diagnosis");
        }
        setTreatmentFlag(false)
      }
      if (tabNum && tabNum != 'null') {
        //偏好设置切换
        setCurrentTab(getDiagnosesKey(Number(tabNum)))
        return
      }
    } else {
      if (location?.state?.key) {
        setCurrentTab(location.state?.key);
      } else {
        setCurrentTab("diagnosis");
      }
    }
  }, [tabName])

  const query = useQuery()

  const registrationId = query.get('registrationId') as string

  const treatmentId = query.get('treatmentId') as string

  useEffect(() => {
    if (registration?.patientId || registrations?.patientId) {
      // dispatch(
      //   getTreatmentNotice(registration?.patientId || registrations?.patientId)
      // )
      //   .then(unwrapResult)
      //   .then((notice) => setNotice(notice))
      //   .catch(() => {
      //     // do nothing
      //   })
      dispatch(
        getPatientDetail(registration?.patientId || registrations?.patientId)
      )
        .then(unwrapResult)
        .then((pateint) => setPatient(pateint))
        .catch(() => {
          // do nothing.
        })
    }
  }, [registration, registrations])

  useEffect(() => {
    if (registrationId)
      dispatch(getTab(registrationId))
        .then(unwrapResult)
        .then((v: any) => {
          setTabName(v)
        })
    return () => {
      dispatch(setSearchMedical([]))
    }
  }, [preferencesSetFlag])

  useEffect(() => {
    if (
      currentTab &&
      currentTab !== 'editor' &&
      Tag &&
      getDiagnosesState(currentTab || 'diagnosis')
    )
      dispatch(
        getCutTab({
          medicalRecord: getDiagnosesState(currentTab || 'diagnosis'),
          registrationId: registrationId,
        })
      )
        .then(unwrapResult)
        .then((v: any) => {
          setMessage(v)
          if (v == '操作成功') {
            setIsModalVisible(false)
          } else {
            setIsModalVisible(true)
          }
        })
  }, [currentTab])

  useEffect(() => {
    if (treatment || treatmentO || treatmentT || treatmentB) {
      setTreatmentFlag(true)
    }
  }, [treatment, treatmentO, treatmentT, treatmentB])

  useEffect(() => {
    if (isAddFollowUpModalVisible) {
      if (treatment?.id) {
        setTrData(treatment)
      } else if (treatmentO?.id) {
        setTrData(treatmentO)
      } else if (treatmentT?.id) {
        setTrData(treatmentT)
      }
    }
  }, [isAddFollowUpModalVisible, treatment, treatmentO, treatmentT])

  useEffect(() => {
    if (!registration) getRegistration()
  }, [dispatch, registration])

  const getRegistration = () => {
    dispatch(
      getTreatments({
        registrationId,
        treatmentId,
      })
    )
      .then(unwrapResult)
      .then((v: any) => {
        setAppointmentRemark(v.appointmentRemark)
        setInsuranceBean(v.insuranceBean)
        setPatientInsurance(v.patientInsurance)
        setRegistration({ ...v?.registration, labelList: v?.labels })
        const data = {
          patientId: v?.registration.patientId,
          patientName: v?.registration.patientName,
          treatmentDoctorId: v?.registration.treatmentDoctorId,
          treatmentDoctorName: v?.registration.treatmentDoctorName,
        }
        dispatch(oweMoney({ patientId: v?.registration?.patientId })) //查询是否欠费
          .then(unwrapResult)
          .then((res) => {
            setOweMoneyNum(res.arrearageAmountSum)
            setoweLabel(res.labels)
          })
        dispatch(setPatientInfo(data))
      })
  }

  useEffect(() => {
    return () => {
      sessionStorage.removeItem('isDrawer')
    }
  }, [])

  // 查询人员累计信息
  const searchPsnSum = (
    isShowModal: boolean,
    serverType: serverType,
    psnNo: string,
    insuranceRegion: string,
    responseString?: string
  ) => {
    dispatch(
      searchPsnSumAsync({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          psnNo,
          insuranceRegion,
        },
      })
    )
      .then(unwrapResult)
      .then((res) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                insuranceBean?.countryHospitalNb,
                insuranceBean?.secretToken,
              ],
              message: '人员累计信息查询',
            },
            (call: any, err) => {
              if (!err) {
                searchPsnSum(
                  isShowModal,
                  'response',
                  psnNo,
                  insuranceRegion,
                  JSON.stringify(call.netmessage)
                )
              } else {
                setSearchPsnSumDisabled(false)
              }
            }
          )
        } else {
          if (isShowModal) {
            setSumVisible(true)
            setSumList(res)
            setSearchPsnSumDisabled(false)
          }
        }
      })
      .catch((ex) => {
        setSearchPsnSumDisabled(false)
      })
  }

  const treatmentSignVerification = () =>{
    try{
      //1.获取版本号 （确保通信正常）
      let result = InvokeCWCAService({
        "function":"SOF_GetVersion"
      });
      if(!result || result?.Success == 0){
        return;
      }
      //2.加载动态库
      result = InvokeCWCAService({"function":"SOF_LoadLibrary","Path":"mtoken_gm3000.dll", "Vendor":""});
      if(!result || result?.Success == 0){
        return;
      }
      //3.获取设备数量
      result = InvokeCWCAService({"function":"SOF_GetKeyCount"});
      if(!result || result?.Success == 0){
        return;
      }
      if(result?.RETURN != 1){
        notification.error({
          message: '未检测到密钥，请您插入USB密钥后再试。',
        })
        return;
      }
      //4.获取用户唯一ID
      result = InvokeCWCAService({"function":"SOF_GetUserList"});
      if(!result || result?.Success == 0){
        return;
      }
      const certOptId = result?.RETURN?.split('||')[1];
      if(!certOptId || certOptId.length<=10){
        notification.error({
          message: '获取用户证书信息失败。请联系管理员或者重新插拔密钥key后再试。',
        })
        return;
      }
      //5.拼接签名内容 ((((姓名^$^$^科室名称^$^$^医生姓名^$^$^主诉^$^$^现病史^$^$^既往史^$^$^过敏史^$^$^疾病诊断^$^$^病史^$^$^体格检查^$^$^检验检查^$^$^治疗意见))))
      const textForSign = "(((("+registration?.patientName+"^$^$^"+treatment?.treatmentDepartmentName+"^$^$^"+treatment?.treatmentDoctorName+"^$^$^"+treatment?.chiefComplaint+"^$^$^"+
        treatment?.historyOfPresentIllness+"^$^$^"+treatment?.pastMedicalHistory+"^$^$^"+treatment?.allergen+"^$^$^"+treatment?.disease+"^$^$^"+treatment?.personalHistory+treatment?.familyHistory+
        treatment?.vaccineHistory+treatment?.menstrualHistory+treatment?.maritalHistory+"^$^$^"+treatment?.physicalExamination+"^$^$^"+treatment?.inspectionCheck+"^$^$^"+treatment?.treatmentConclusion+"^$^$^"+treatment?.updateTime+"))))";
      //6.获取base64证书
      result = InvokeCWCAService({"function":"SOF_ExportUserCert", "CertOptID":certOptId})
      if(!result || result?.Success == 0){
        return;
      }
      //7.数据验签
      result = InvokeCWCAService({"function":"SOF_VerifySignedData","Base64Cert": result?.RETURN,"InData":textForSign,"SignValue":registrations?.cwcaSignature})
      let confirmProps;
      if(result && result?.Success == 1){
        confirmProps = {
          title: '病历验签',
          content: (
            <div>
              <p>患者：{treatment?.patientName}</p>
              <p>签名医生：{treatment?.treatmentDoctorName}</p>
              <p>签名时间：{registrations?.cwcaSigntime}</p>
              <p style={{color:"#298b08",fontWeight:"bold"}}>验签通过，病历未篡改</p>
            </div>
          ),
          icon: null,
        }
      }else{
        confirmProps = {
          title: '病历验签',
          content: (
            <div>
              <p>患者：{treatment?.patientName}</p>
              <p>签名医生：{treatment?.treatmentDoctorName}</p>
              <p>签名时间：{registrations?.cwcaSigntime}</p>
              <p style={{color:"orangered"}}>验签不通过，病历已被更新，请重新签章</p>
            </div>
          ),
          cancelText: '确定',
          okText:'擦除签章',
          icon: null,
          onOk: () => {
            dispatch(removeCASign({"registrationId":registration?.id}))
              .then(unwrapResult)
              .then((res: any) => {
                notification.success({
                  message: '操作成功。',
                })
                getRegistration();
              }).catch((ev) => {
                notification.error({
                  message: '操作失败。'+ev.message,
                })
              })
          }
        }
      }
      Modal.confirm(confirmProps)
    }catch(e){
      console.log(e);
    }finally {
      // setCASignatureKeyLoading(false);
      InvokeCWCAService({"function":"SOF_FreeLibrary"});
    }
  }


  const treatmentSign = () =>{
    try{
      //1.获取版本号 （确保通信正常）
      let result = InvokeCWCAService({
        "function":"SOF_GetVersion"
      });
      if(!result || result?.Success == 0){
        return;
      }
      //2.加载动态库
      result = InvokeCWCAService({"function":"SOF_LoadLibrary","Path":"mtoken_gm3000.dll", "Vendor":""});
      if(!result || result?.Success == 0){
        return;
      }
      //3.获取设备数量
      result = InvokeCWCAService({"function":"SOF_GetKeyCount"});
      if(!result || result?.Success == 0){
        return;
      }
      if(result?.RETURN != 1){
        notification.error({
          message: '未检测到密钥，请您插入USB密钥后再试。',
        })
        return;
      }
      //4.获取用户唯一ID
      result = InvokeCWCAService({"function":"SOF_GetUserList"});
      if(!result || result?.Success == 0){
        return;
      }
      const keyName = result?.RETURN?.split('||')[0];
      if(keyName != treatment?.treatmentDoctorName){
        notification.error({
          message: '请插入医生本人的密钥后再进行操作',
        })
        return;
      }
      const certOptId = result?.RETURN?.split('||')[1];
      if(!certOptId || certOptId.length<=10){
        notification.error({
          message: '获取用户证书信息失败。请联系管理员或者重新插拔密钥key后再试。',
        })
        return;
      }
      //5.拼接签名内容 ((((姓名^$^$^科室名称^$^$^医生姓名^$^$^主诉^$^$^现病史^$^$^既往史^$^$^过敏史^$^$^疾病诊断^$^$^病史^$^$^体格检查^$^$^检验检查^$^$^治疗意见))))
      const textForSign = "(((("+registration?.patientName+"^$^$^"+treatment?.treatmentDepartmentName+"^$^$^"+treatment?.treatmentDoctorName+"^$^$^"+treatment?.chiefComplaint+"^$^$^"+
      treatment?.historyOfPresentIllness+"^$^$^"+treatment?.pastMedicalHistory+"^$^$^"+treatment?.allergen+"^$^$^"+treatment?.disease+"^$^$^"+treatment?.personalHistory+treatment?.familyHistory+
      treatment?.vaccineHistory+treatment?.menstrualHistory+treatment?.maritalHistory+"^$^$^"+treatment?.physicalExamination+"^$^$^"+treatment?.inspectionCheck+"^$^$^"+treatment?.treatmentConclusion+"^$^$^"+treatment?.updateTime+"))))";
      //6.数据签名
      result = InvokeCWCAService({"function":"SOF_SignData","CertOptID":certOptId,"InData":textForSign})
      if(!result || result?.Success == 0){
        notification.info({
          message: result?.Msg,
        })
        return;
      }
      const signText = result?.RETURN
      Modal.confirm({
        title: '确认签章',
        content: (
          <div>
            <p>患者：{treatment?.patientName} </p>
            <p>签名医生：{treatment?.treatmentDoctorName} </p>
            <p>电子签名信息如下： </p>
            <p>{signText}</p>
            <p style={{color:"orangered"}}>请确认您的病历信息已填写完毕，并点击签章。</p>
          </div>
        ),
        okText: '签章',
        cancelText: '取消',
        icon: null,
        onOk: () => {
          dispatch(treatmentCASign({"registrationId":registration?.id,"signature":signText}))
            .then(unwrapResult)
            .then((res: any) => {
              notification.success({
                message: '签章成功。',
              })
              getRegistration();
            }).catch((ev) => {
              notification.error({
                message: '签章失败。'+ev.message,
              })
            })
          }
      })
    }catch(e){
      console.log(e);
    }finally {
      // setCASignatureKeyLoading(false);
      InvokeCWCAService({"function":"SOF_FreeLibrary"});
    }
  }

  return (
    <div className={styles.container}>
      <div
        style={{
          height: '30px',
          background: '#fff',
          margin: '10px 20px 0 20px',
          borderRadius: '4px 0 0 0',
        }}
      >
        <Row
          style={{
            width: '76%',
            color: '#333333',
            fill: '#333333',
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            height: '30px',
          }}
        >
          <div
            style={{
              width: '0',
              height: '0',
              borderColor:
                registrations?.insuranceCode !== 'ZIFEI'
                  ? 'transparent #23B283'
                  : 'transparent #027aff' /*上下颜色 左右颜色*/,
              borderWidth: '0 0 30px 31px',
              borderStyle: 'solid',
              position: 'relative',
              borderRadius: '4px 0 0 0',
            }}
          >
            <img
              src={registrations?.insuranceCode !== 'ZIFEI' ? ybPng : zfPng}
              alt=''
              style={{
                width: '20px',
                height: '20px',
                position: 'absolute',
                left: '-34px',
              }}
            ></img>
          </div>
          <Row
            style={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: '-10px',
            }}
          >
            {registrations?.patientSex === Gender.Female ? (
              <FemaleFilled
                style={{
                  fill: '#848484',
                  color: '#848484',
                }}
              />
            ) : (
              <MaleFilled
                style={{
                  fill: '#848484',
                  color: '#848484',
                }}
              />
            )}
            {registrations?.patientName == undefined
              ? ' '
              : registrations?.patientName}{' '}
            {registrations?.insuranceCode !== 'ZIFEI'
              ? `${
                  insuranceBean?.insuranceName == undefined
                    ? ' '
                    : insuranceBean?.insuranceName
                } 个账余额：${
                  patientInsurance?.zhye == undefined
                    ? ' '
                    : patientInsurance?.zhye
                }元 `
              : ' '}
            {patientInsurance?.memberTypemc && patientInsurance?.memberTypemc}{' '}
            {'（'}
            {getGenderName(registrations?.patientSex)},
            {getAge(
              registrations?.patientAge,
              registrations?.patientMonth,
              '周岁'
            )}
            {'）'}
            {registrations?.appointmentId &&
              registrations?.appointmentId != -1 && (
                <AppointmentIcon
                  style={{
                    marginLeft: '5px',
                    width: '18px',
                    height: '18px',
                  }}
                />
              )}
            {oweMoneyNum > 0 && oweMoneyShow?.openOwe == 1 && (
              <>
                <Tooltip title={`欠费金额 ${oweMoneyNum} 元`}>
                  <div style={{ marginTop: '8px' }}>
                    <OweMoneyIcon
                      style={{
                        marginLeft: '5px',
                        width: '18px',
                        height: '18px',
                      }}
                    />
                  </div>
                </Tooltip>
                {/* {owelabel.length
                  ? owelabel.map((v: any) => (
                      <Tooltip key={v.id} title={v.name}>
                        <span
                          style={{
                            backgroundColor: v.backgroundColor,
                            color: v.fontColor,
                            width: 18,
                            height: 18,
                            marginLeft: 3,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          {v.headName}
                        </span>
                      </Tooltip>
                    ))
                  : ''} */}
              </>
            )}
            {!!registrations?.labelList?.length &&
              registrations?.labelList?.map((v: any) => (
                <Tooltip key={v.id} title={v.name}>
                  <span
                    className={styles.label}
                    style={{
                      backgroundColor: v.backgroundColor,
                      color: v.fontColor,
                    }}
                  >
                    {v.headName}
                  </span>
                </Tooltip>
              ))}
            <p
              style={{
                margin: '0 0 0 5px',
                display: 'flex',
                alignItems: 'center',
                fontSize: '14px',
                color: 'rgb(7, 193, 96)',
              }}
            >
              {patientInsurance?.ybType?.toString()
                ? getYBTypeName(patientInsurance?.ybType?.toString())
                : ''}
            </p>
            {patientInsurance?.patientId?.toString() ? (
              <p
                style={{
                  margin: '0 0 0 10px',
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: '14px',
                  color: '#FAB714',
                }}
              >
                {patientInsurance?.patientId == -1
                  ? '本年首次'
                  : notice?.isFirstDiagnosisThisYear
                  ? '本年首次'
                  : '本年复诊'}
              </p>
            ) : (
              ''
            )}
            <div
              style={{
                margin: '0 5px 0 10px',
                cursor: 'pointer',
                fontStyle: 'normal',
                display: 'flex',
                alignItems: 'center',
              }}
              onClick={() => dispatch(setPatientModalVisible(true))}
            >
              修改
              <EditOutlined
                mode='small'
                style={{
                  color: '#2092c9',
                }}
              />
            </div>
            {registrations?.insuranceCode &&
              registrations?.insuranceCode != 'ZIFEI' && (
                <Popover
                  content={
                    <div>
                      <p>姓名：{patientInsurance?.name}</p>
                      <p style={{ fontWeight: 'bold' }}>
                        个账余额：{patientInsurance?.zhye}
                      </p>
                      <p style={{ fontWeight: 'bold' }}>
                        当年账户余额：{patientInsurance?.dnzhye}
                      </p>
                      <p style={{ fontWeight: 'bold' }}>
                        历年账户余额：{patientInsurance?.lnzhye}
                      </p>
                      <p>工作单位：{patientInsurance?.brdw}</p>
                      <p>参保险种：{patientInsurance?.cbxzmc}</p>
                      <p>人员类型：{patientInsurance?.memberTypemc}</p>
                    </div>
                  }
                  placement='bottom'
                  title='更多医保信息'
                >
                  <InfoCircleTwoTone
                    style={{ color: '#2092c9', fontSize: '18px' }}
                  />
                </Popover>
              )}
            {registrations?.insuranceCode &&
              registrations?.insuranceCode != 'ZIFEI' && (
                <Button
                  disabled={searchPsnSumDisabled}
                  type='link'
                  onClick={() => {
                    console.log(patientInsurance,"patientInsurance");
                    
                    if (patientInsurance?.ybPersonalNo) {
                      setSearchPsnSumDisabled(true)
                      //如果联通医保，则查询最新的
                      if (pingState) {
                        searchPsnSum(
                          true,
                          'requestString',
                          patientInsurance.ybPersonalNo,
                          patientInsurance?.insuranceRegion
                        )
                      } else {
                        //否则查询本地数据库的
                        // dispatch(searchPsnSumForLocalAsync({
                        dispatch(
                          searchPsnSumForLocalAsync({
                            psnNo: patientInsurance.ybPersonalNo,
                          })
                        )
                          .then(unwrapResult)
                          .then((res) => {
                            setSumVisible(true)
                            setSumList(res)
                            setSearchPsnSumDisabled(false)
                          })
                          .catch((ex) => {
                            setSearchPsnSumDisabled(false)
                          })
                      }
                    } else {
                      notification.warn({
                        message: '查询不到该患者的医保信息',
                        duration: 1.5,
                      })
                    }
                  }}
                >
                  查询医保账户累计
                </Button>
              )}
            {appointmentRemark && (
              <Popover
                content={
                  <div>
                    <p>预约备注:{appointmentRemark}</p>
                  </div>
                }
                placement='bottom'
                title=''
              >
                <div
                  style={{
                    width: '150px',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                >
                  预约备注： {appointmentRemark}
                </div>
              </Popover>
            )}
          </Row>
        </Row>
      </div>
      <TabBar
        activeKey={backSelectKey ? backSelectKey : currentTab}
        defaultActiveKey={backSelectKey ? backSelectKey : currentTab}
        onChange={(key) => {
          setBackSelectKey(undefined)
          if (key === 'editor' || key === 'Surgical') {
            if (treatmentFlag) {
              setCurrentTab(key as TreatmentTabKey)
            } else {
              notification.warn({
                message: '请填写并保存病例',
              })
            }
          } else {
            setCurrentTab(key as TreatmentTabKey)
          }
        }}
        className={styles.tabs}
        destroyInactiveTabPane
      >
        {tabName?.map((v: any, i: number) => {
          return (
            <TabPane
              tab={getDiagnosesName(v.diagnoses)}
              key={getDiagnosesKey(v.diagnoses)}
            >
              {/* {!(
                (v.diagnoses === 2 && currentTab === 'tcmCases') ||
                (v.diagnoses === 1 && currentTab === 'diagnosis') ||
                (v.diagnoses === 3 && currentTab === 'toothCases')
              ) ? (
                */}
              <Row
                style={{
                  height: '5px',
                }}
              ></Row>

              {v.diagnoses === 1 && currentTab === 'diagnosis' ? (
                <Diagnosis
                  saveEdit={() => {
                    setTimeout(() => {
                      setCurrentTab('editor')
                    }, 300)
                  }}
                  registrations={registrations}
                  owelabel={owelabel}
                  oweMoneyNum={oweMoneyNum}
                  oweMoneyShow={oweMoneyShow}
                  insuranceBean={insuranceBean}
                  patientInsurance={patientInsurance}
                  PreferencesSetChange={() =>
                    setPreferencesSetFlag(!preferencesSetFlag)
                  }
                />
              ) : v.diagnoses === 2 && currentTab === 'tcmCases' ? (
                <TCMCases
                  saveEdit={() => {
                    setTimeout(() => {
                      setCurrentTab('editor')
                    }, 300)
                  }}
                  registrations={registrations}
                  owelabel={owelabel}
                  oweMoneyNum={oweMoneyNum}
                  oweMoneyShow={oweMoneyShow}
                  insuranceBean={insuranceBean}
                  patientInsurance={patientInsurance}
                  PreferencesSetChange={() =>
                    setPreferencesSetFlag(!preferencesSetFlag)
                  }
                />
              ) : v.diagnoses === 3 && currentTab === 'toothCases' ? (
                <OralCases
                  saveEdit={() => {
                    setTimeout(() => {
                      setCurrentTab('editor')
                    }, 300)
                  }}
                  registrations={registrations}
                  owelabel={owelabel}
                  oweMoneyNum={oweMoneyNum}
                  oweMoneyShow={oweMoneyShow}
                  insuranceBean={insuranceBean}
                  patientInsurance={patientInsurance}
                  PreferencesSetChange={() =>
                    setPreferencesSetFlag(!preferencesSetFlag)
                  }
                />
              ) : v.diagnoses === 4 && currentTab === 'BeautyClinic' ? (
                <BeautyClinic
                  tabKey={currentTab}
                  saveEdit={() => {
                    setTimeout(() => {
                      dispatch(getTab(registrationId))
                        .then(unwrapResult)
                        .then((v: any) => {
                          setTabName(v)
                        })
                    }, 300)
                  }}
                  PreferencesSetChange={() =>
                    setPreferencesSetFlag(!preferencesSetFlag)
                  }
                />
              ) : v.diagnoses === 5 && currentTab === 'Surgical' ? (
                <Surgical
                  saveEdit={() => {
                    setTimeout(() => {
                      setCurrentTab('editor')
                    }, 300)
                  }}
                  PreferencesSetChangeFn={() =>
                    setPreferencesSetFlag(!preferencesSetFlag)
                  }
                />
              ) : v.diagnoses === 6 && currentTab === 'SurgicalWord' ? (
                <SurgicalWord
                  saveEdit={() => {
                    setTimeout(() => {
                      setCurrentTab('editor')
                    }, 300)
                  }}
                  PreferencesSetChange={() =>
                    setPreferencesSetFlag(!preferencesSetFlag)
                  }
                />
              ) : (
                <></>
              )}
            </TabPane>
          )
        })}

        {
          openAdvancedMedical && (
                <TabPane tab='高级病历' key='yimei'>
                  <BeautyClinicDC/>
                </TabPane>
          )
        }
        <TabPane tab='医嘱处方' key='editor'>
          {/* <Row
            style={{
              fontStyle: 'oblique',
              color: '#2092c9',
              fill: '#2092c9',
              display: 'flex',
              alignItems: 'center',
              position: 'relative',
            }}
          >
            {registrations?.patientSex === Gender.Female ? (
              <FemaleFilled
                style={{
                  fill: 'currentColor',
                  color: '#2092c9',
                }}
              />
            ) : (
              <MaleFilled
                style={{
                  fill: 'currentColor',
                  color: '#2092c9',
                }}
              />
            )}
            {registrations?.patientName}{' '}
            {registrations?.insuranceCode !== 'ZIFEI'
              ? `${insuranceBean?.insuranceName} 个账余额：${patientInsurance?.zhye}元 `
              : ''}
            {patientInsurance?.memberTypemc}
            {'（'}
            {getGenderName(registrations?.patientSex)},
            {getAge(
              registrations?.patientAge,
              registrations?.patientMonth,
              '周岁'
            )}
            {'）'}
            {registrations?.appointmentId &&
              registrations?.appointmentId != -1 && (
                <AppointmentIcon
                  style={{ marginLeft: '5px', width: '18px', height: '18px' }}
                />
              )}
            <div
              style={{
                margin: '0 5px 0 10px',
                cursor: 'pointer',
                fontStyle: 'normal',
                display: 'flex',
                alignItems: 'center',
              }}
              onClick={() => dispatch(setPatientModalVisible(true))}
            >
              修改
              <EditOutlined
                mode='small'
                style={{
                  color: '#2092c9',
                }}
              />
            </div>
            {registrations?.insuranceCode &&
              registrations?.insuranceCode != 'ZIFEI' && (
                <Popover
                  content={
                    <div>
                      <p>姓名：{patientInsurance?.name}</p>
                      <p style={{ fontWeight: 'bold' }}>
                        账户余额：{patientInsurance?.zhye}
                      </p>
                      <p style={{ fontWeight: 'bold' }}>
                        当年账户余额：{patientInsurance?.dnzhye}
                      </p>
                      <p style={{ fontWeight: 'bold' }}>
                        历年账户余额：{patientInsurance?.lnzhye}
                      </p>
                      <p>工作单位：{patientInsurance?.brdw}</p>
                      <p>参保险种：{patientInsurance?.cbxzmc}</p>
                      <p>人员类型：{patientInsurance?.memberTypemc}</p>
                    </div>
                  }
                  placement='bottom'
                  title='更多医保信息'
                >
                  <InfoCircleTwoTone
                    style={{ color: '#2092c9', fontSize: '18px' }}
                  />
                </Popover>
              )}
            {appointmentRemark && (
              <div style={{ position: 'absolute', right: 10, top: 0 }}>
                预约备注： {appointmentRemark}
              </div>
            )}
          </Row> */}
          <Row
            style={{
              height: '5px',
            }}
          ></Row>
          <RecipeListEditor
            patientName={
              registrations?.insuranceCode &&
              registrations?.insuranceCode != 'ZIFEI'
                ? patientInsurance?.name
                : registrations?.patientName
            }
            yzcfTag={true}
            currentTab='editor'
            refreshTreament={() => {
              getRegistration()
            }}
          />
        </TabPane>
        <TabPane tab='就诊总览' key='medicalOverview'>
          <MedicalOverview
            registrations={registrations}
            activeKey={currentTab}
          />
        </TabPane>
      </TabBar>
      <Row
        style={{
          position: 'absolute',
          height: '30px',
          right: 20,
          top: 10,
          color: theme.tc3,
          fill: theme.tc3,
          fontSize: '1rem',
          padding: '0 20px',
        }}
        align='middle'
      >
        {(registration || registrations) && (
          <>
            {/* <Col
              style={{
                width: '1.5rem',
                height: '1.5rem',
                color: theme.c1,
                fill: theme.c1,
              }}
            >
              {registrations?.patientSex === Gender.Female ? (
                <FemaleFilled />
              ) : (
                <MaleFilled />
              )}
            </Col>
            <Col style={{ marginLeft: 10, marginRight: 10 }}>
              {registrations?.patientName}(
              {getGenderName(registrations?.patientSex)},
              {getAge(
                registrations?.patientAge,
                registrations?.patientMonth,
                '周岁'
              )}
              )
            </Col>
            <div
              style={{ marginRight: 2, cursor: 'pointer' }}
              onClick={() => setIsPatientModalVisible(true)}
            >
              修改
            </div>
            <EditOutlined mode='small' /> */}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                color: '#999999',
                marginRight:'10px'
              }}
            >
            {treatment && registrations?.cwcaSignature && (
              <a style={{display:"flex",alignItems:"center"}} onClick={() => {
                Modal.confirm({
                  title: '签章验证',
                  okText: '已插入USB密钥',
                  content: (
                    <div>
                      <p>签名密钥：{registrations?.cwcaSignature}</p>
                      <p style={{color:"orangered"}}>插入USB密钥后，进行验签。</p>
                    </div>
                  ),
                  onOk: () => {
                    treatmentSignVerification();
                  },
                })
              }}><CAIcon
                style={{
                  marginLeft: '5px',
                  width: '32px',
                  height: '32px',
                }}
              />已签章&nbsp;</a>
            )}
            {treatment && !registrations?.cwcaSignature && cwcaOptId &&
              <a style={{display:"flex",alignItems:"center"}} onClick={() => {
                Modal.confirm({
                  title: '电子签章',
                  okText: '已插入USB密钥',
                  content: (
                    <div>
                      <p>请确认您的病历信息已填写完毕。</p>
                      <p style={{color:"orangered"}}>插入USB密钥后，进行电子签章。</p>
                    </div>
                  ),
                  onOk: () => {
                    // setCASignatureKeyLoading(true);
                    // setTimeout(() => {
                      treatmentSign();
                    // }, 1000)
                  },
                })
              }}><UNCAIcon
                  style={{
                    marginLeft: '5px',
                    width: '32px',
                    height: '32px',
                  }}
              />未签章&nbsp;</a>
            }
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                color: '#999999',
              }}
            >

              <ImportOutlined
                style={{
                  fontSize: '20px',
                }}
              />
              <Button
                type='text'
                style={{
                  marginLeft: -10,
                  marginTop: '-4px',
                  fontSize: '16px',
                  color: '#0479ff',
                }}
                onClick={() => {
                  dispatch(setMedicalRecordVisible(true))
                }}
              >
                查看病程记录
              </Button>
            </div>
            {(currentTab == 'diagnosis' ||
              currentTab == 'tcmCases' ||
              currentTab == 'toothCases') &&
              showFollowUp &&
              (currentTab == 'diagnosis' ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    color: !treatment ? '#999999' : '#0479ff',
                  }}
                >
                  <ImportOutlined
                    style={{
                      fontSize: '20px',
                    }}
                  />
                  <Button
                    type='text'
                    style={{
                      marginLeft: -10,
                      marginTop: '-4px',
                      fontSize: '16px',
                      color: !treatment?.id ? '#999999' : '#0479ff',
                    }}
                    disabled={treatment?.id ? false : true}
                    onClick={() => {
                      setIsAddFollowUoModalVisible(true)
                    }}
                  >
                    创建随访
                  </Button>
                </div>
              ) : currentTab == 'tcmCases' ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    color: !treatmentT?.id ? '#999999' : '#0479ff',
                  }}
                >
                  <ImportOutlined
                    style={{
                      fontSize: '20px',
                    }}
                  />
                  <Button
                    type='text'
                    style={{
                      marginLeft: -10,
                      marginTop: '-4px',
                      fontSize: '16px',
                      color: !treatment?.id ? '#999999' : '#0479ff',
                    }}
                    disabled={treatmentT?.id ? false : true}
                    onClick={() => {
                      setIsAddFollowUoModalVisible(true)
                    }}
                  >
                    创建随访
                  </Button>
                </div>
              ) : currentTab == 'toothCases' ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    color: !treatmentO?.id ? '#999999' : '#0479ff',
                  }}
                >
                  <ImportOutlined
                    style={{
                      fontSize: '20px',
                    }}
                  />
                  <Button
                    type='text'
                    style={{
                      marginLeft: -10,
                      marginTop: '-4px',
                      fontSize: '16px',
                      color: !treatmentO?.id ? '#999999' : '#0479ff',
                    }}
                    disabled={treatmentO?.id ? false : true}
                    onClick={() => {
                      setIsAddFollowUoModalVisible(true)
                    }}
                  >
                    创建随访
                  </Button>
                </div>
              ) : (
                ''
              ))}

            <div
              style={{ marginRight: 2, marginLeft: 5, cursor: 'pointer' }}
              onClick={() => {
                dispatch(setPatientInfo({}))
                // 判断医生工作站处方查询跳转标志cfcxFlag,有的话依旧返回到医生工作站
                if( query.get('cfcxFlag')){
                  dispatch(
                    traceRoute({
                      name: '医生工作站',
                      path: '/doctor/station',
                      state: {
                        station: query.get('station'),
                      },
                    })
                  )
                }else{
                  dispatch(
                    traceRoute({
                      name: '医生门诊',
                      path: '/patients',
                    })
                  )
                }
              
              }}
            >
              返回
            </div>
            <BackOutlined mode='small' />
          </>
        )}
      </Row>
      <PatientModal
        registrations={registrations}
        registrationId={registration?.id || registrations?.id}
        patient={patient}
        visible={isPatientModalVisible}
        onCancel={() => dispatch(setPatientModalVisible(false))}
        onOk={() => {
          getRegistration()
          dispatch(setPatientModalVisible(false))
        }}
      />
      <Modal
        width={400}
        title={null}
        visible={isModalVisible}
        footer={null}
        centered
        closable={false}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CloseCircleOutlined
            style={{ fill: 'red', fontSize: '18px', marginRight: '20px' }}
          />
          <p className={styles.message}>{message}</p>
        </div>
      </Modal>

      <AddFollowUpModal
        treatmentData={trData}
        treatmentTag={isAddFollowUpModalVisible ? true : false} //病例那边点开不需要查询条件以及治疗计划
        isModalVisible={isAddFollowUpModalVisible}
        onCancel={() => {
          setIsAddFollowUoModalVisible(false)
          setTrData([])
        }}
        onOk={() => {
          setIsAddFollowUoModalVisible(false)
          setTrData([])
        }}
      />
      <MedicalRecordModal registration={registration || registrations} />
      <Modal
        visible={detailVisible}
        title={'病历信息'}
        onCancel={() => {
          dispatch(setDetailVisible(false))
          dispatch(setMedicalRecordVisible(true))
        }}
        footer={null}
        width={1200}
        bodyStyle={{
          height: 600,
        }}
      >
        <div className={styles.content}>
          <TreatmentBoxList
            tag={true}
            page={treatmentLeftPage}
            type={undefined}
          />
        </div>
        <Row
          justify='end'
          style={{
            marginTop: 8,
          }}
          onClick={() => {
            dispatch(setDetailVisible(false))
            dispatch(setMedicalRecordVisible(true))
          }}
        >
          <Button>返回（ESC）</Button>
        </Row>
      </Modal>
      <Modal
        className={styles.ModalContent}
        cancelText='返回'
        okButtonProps={{
          style: { display: 'none' },
        }}
        visible={sumVisible}
        title={`${patientInsurance?.name} ${sumList?.insutype} 账户年度累计`}
        onCancel={() => setSumVisible(false)}
      >
        <div style={{ marginTop: '-10px' }}>
          <span style={{ fontWeight: 'bold' }}>
            当年账户余额：{patientInsurance?.dnzhye}
          </span>
          <span style={{ fontWeight: 'bold', marginLeft: '10px' }}>
            历年账户余额：{patientInsurance?.lnzhye}
          </span>
        </div>
        <div>
          <span style={{ fontWeight: 'bold' }}>
            工作单位：{patientInsurance?.brdw}
          </span>
        </div>
        <div style={{ marginBottom: '10px' }}>
          <span style={{ fontWeight: 'bold' }}>
            人员类型：{patientInsurance?.memberTypemc}
          </span>
        </div>
        <div>
          <Table
            scroll={{ y: 300 }}
            dataSource={sumList?.currYearInsCum}
            columns={[
              {
                title: '累计类型名称',
                align: 'left',
                width: '82%',
                render: (_, r) => {
                  return r.name
                },
              },
              {
                title: '累计数',
                align: 'center',
                render: (_, r) => {
                  return r.cum
                },
              },
            ]}
            pagination={false}
          />
        </div>
      </Modal>
    </div>
  )
}
