/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/prop-types */
import { message, Modal, ModalProps, notification, Table, Tabs } from 'antd'
import { TableRowSelection } from 'antd/lib/table/interface'
import React, { ReactElement, useEffect, useState } from 'react'
import { EditableList } from '../../compnents/list/EditableList'
import { TabPane } from '../../compnents/widgets/TabBar'
import { DataType } from '../../features/putstorage/putstorage'
import { List } from '../../models/page'
import { RegisterColumns, RegisterElseColumns } from './Columns'
import { RegisterQuery } from './RegisterQuery'
import styles from '../Layout.module.css'
import { RootDispatch } from '../../app/store'
import { useDispatch, useSelector } from 'react-redux'
import {
  accountCheckAsync,
  detailCheckAsync,
  elseWhereDownloadCheckAsync,
  fileDownloadAsync,
  filePageParseAsync,
  getAccountCheckListAsync,
  getAllopatryListAsync,
  updateFireAsync,
} from '../layoutSlice'
import { AccountCheckListParams } from '../../services/config'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  selectInsuranceArray,
  selectTenantAddress,
  selectTenantAreaCd,
  selectTenantCategory,
  selectTenantId,
  selectTenantName,
  selectUserId,
  selectUserName,
} from '../../app/applicationSlice'
import moment from 'moment'
import {
  DateTimeFormatSimpleOnly,
  DateTimeFormatSimpleOnlyOne,
} from '../../models/datetime'
import { medicalSendHttp, strParse } from '../../utils/MedicalUtils'
import { RegisterDetailModal } from './RegisterDetailModal'
import { setState } from '../../features/sales/Statement/statementSlice'
import { RecipeStatus } from '../../models/recipe'

export interface RegisterProps {
  onReset: (insuranceCode: string) => void
  onDetail: () => void
  onDownLoad: (viewList: any[], isLocal: string) => void
  onCancel: () => void
}

export interface RegisterArgs {
  insuranceItem: any
  selectItem: any
  serverType: string
  callback: (call: string) => void
  resolve: void
  reject: void
}

export const RegisterModal: React.FC<ModalProps & RegisterProps> = ({
  visible,
  onOk,
  onCancel,
  onReset,
  onDetail,
  onDownLoad,
}): ReactElement => {
  const [queries, setQueries] = useState<AccountCheckListParams>({
    current: 1,
    size: 10,
  })

  const dispatch = useDispatch<RootDispatch>()

  const [showStopAction, setShowStopAction] = useState(false)

  const [registerDetailVisible, setRegisterDetailVisible] = useState(false)

  const [selectIds, setSelectIds] = useState<any>([])

  const [viewList, setViewList] = useState<any>([])

  const [elseViewList, setElseViewList] = useState<any>([])

  const [data, setData] = useState<any>()

  const [isNew, setIsNew] = useState(false)

  const [loadState, setLoadState] = useState(false)

  const [isLocal, setIsLocal] = useState('0')

  const insuranceArray = useSelector(selectInsuranceArray)

  const tenantId = useSelector(selectTenantId)

  const userName = useSelector(selectUserName)

  const userId = useSelector(selectUserId)

  const tenantAddress = useSelector(selectTenantAddress)

  const tenantAreaCd = useSelector(selectTenantAreaCd)

  const tenantName = useSelector(selectTenantName)

  const tenantCategory = useSelector(selectTenantCategory)

  const cycleCode = sessionStorage.getItem('cycleCode')

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      setSelectIds(selectedRowKeys)
      // setSelectRow(selectedRows)
    },
  }

  // 9101 上传文件
  const updateFire = (
    insuranceItem: any,
    selectItem: any,
    serverType: any,
    fromInfno: string,
    responseString?: string,
    resolve?: any,
    reject?: any
  ) => {
    dispatch(
      updateFireAsync({
        serverType,
        body: {
          baseData: {
            ...insuranceItem,
            endTime: moment(insuranceItem?.endTime).format(
              DateTimeFormatSimpleOnlyOne
            ),
            userName,
            userId,
            cycleCode,
            tenantCategory,
            tenantAreaCd,
            name: tenantName,
            address: tenantAddress,
            areaCode: tenantAreaCd,
            responseString: responseString ? responseString : null,
          },
          date: selectItem?.accountDate,
          fromInfno: fromInfno,
          isLocal: selectItem?.isLocal,
        },
      })
    )
      .then(unwrapResult)
      .then((res) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                '91011',
                data.url,
                data,
                insuranceItem?.countryHospitalNb,
                insuranceItem?.secretToken,
              ],
              notification: '上传文件',
            },
            (call) => {
              detailCheck(
                call.netmessage.output,
                insuranceItem,
                selectItem,
                'requestString'
              )
            }
          )
        }
      })
      .catch(() => {
        message.destroy()
      })
  }
  // 3202 下载明细
  const detailCheck = (
    info: any,
    insuranceItem: any,
    selectItem: any,
    serverType: any,
    responseString?: string,
    resolve?: any,
    reject?: any
  ) => {
    dispatch(
      detailCheckAsync({
        serverType,
        body: {
          baseData: {
            ...insuranceItem,
            endTime: moment(insuranceItem?.endTime).format(
              DateTimeFormatSimpleOnlyOne
            ),
            userName,
            userId,
            cycleCode,
            tenantCategory,
            tenantAreaCd,
            name: tenantName,
            address: tenantAddress,
            areaCode: tenantAreaCd,
            responseString: responseString ? responseString : null,
          },
          fileQuryNo: info?.file_qury_no,
          setlOptins: tenantAreaCd,
          stmtBegndate: moment(selectItem?.accountDate).format(
            DateTimeFormatSimpleOnly
          ),
          stmtEnddate: moment(selectItem?.accountDate).format(
            DateTimeFormatSimpleOnly
          ),
          clrType: selectItem?.qingsuanlb,
          isLocal: selectItem?.isLocal,
        },
      })
    )
      .then(unwrapResult)
      .then((res) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                insuranceItem?.countryHospitalNb,
                insuranceItem?.secretToken,
              ],
              notification: '下载明细',
            },
            (call) => {
              if (call?.netmessage?.infcode == 1) {
                notification.error({
                  message: call?.netmessage?.err_msg,
                })
              } else {
                fileDownload(
                  call.netmessage.output.fileinfo,
                  insuranceItem,
                  selectItem,
                  'requestString'
                )
              }
            }
          )
        }
      })
      .catch(() => {
        //message.destroy()
      })
  }
  // 9102 下载文件
  const fileDownload = (
    info: any,
    insuranceItem: any,
    selectItem: any,
    serverType: any,
    responseString?: string,
    resolve?: any,
    reject?: any,
    call?: any
  ) => {
    dispatch(
      fileDownloadAsync({
        serverType,
        body: {
          baseData: {
            ...insuranceItem,
            endTime: moment(insuranceItem?.endTime).format(
              DateTimeFormatSimpleOnlyOne
            ),
            userName,
            userId,
            cycleCode,
            tenantCategory,
            tenantAreaCd,
            name: tenantName,
            address: tenantAddress,
            areaCode: tenantAreaCd,
            responseString: responseString ? responseString : null,
          },
          // accountDate: moment(selectItem?.accountDate).format(
          //   DateTimeFormatSimpleOnlyOne
          // ),
          fileQuryNo: info?.file_qury_no,
          filename: info?.filename,
        },
      })
    )
      .then(unwrapResult)
      .then((res) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                insuranceItem?.countryHospitalNb,
                insuranceItem?.secretToken,
              ],
              notification: '下载文件',
            },
            (call) => {
              if (insuranceItem?.insuranceCode == 'CHONGQING') {
                getPageParse(
                  1,
                  call?.netmessage?.output,
                  insuranceItem,
                  selectItem
                )
              } else {
                getPageParse(1, call.netmessage, insuranceItem, selectItem)
              }
            }
          )
        }
      })
      .catch(() => {
        message.destroy()
      })
  }

  const filePageParse = (
    insuranceItem: any,
    selectItem: any,
    responseString?: string,
    resolve?: any,
    reject?: any
  ) => {
    message.loading('正在解析文件', 0)
    dispatch(
      filePageParseAsync({
        baseData: {
          ...insuranceItem,
          endTime: moment(insuranceItem?.endTime).format(
            DateTimeFormatSimpleOnlyOne
          ),
          userName,
          userId,
          cycleCode,
          tenantCategory,
          tenantAreaCd,
          name: tenantName,
          address: tenantAddress,
          areaCode: tenantAreaCd,
          responseString: responseString ? responseString : null,
        },
        accountDate: moment(selectItem?.accountDate).format(
          DateTimeFormatSimpleOnly
        ),
        clrType: selectItem?.qingsuanlb,
        isLocal: selectItem?.isLocal,
        // accountDate: '2022-03-16',
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        onDownLoad(res.CheckTransactionDetails, isLocal)
        message.destroy()
      })
  }

  // 10000 解析文件
  const getPageParse = (
    pageNum: number,
    fileUrl: string,
    insuranceItem: any,
    selectItem: any
  ) => {
    medicalSendHttp(
      {
        trade_args: ['10000', fileUrl, String(pageNum), '10'],
      },
      (call) => {
        if (call.netmessage.length) {
          getPageParse(pageNum + 1, fileUrl, insuranceItem, selectItem)
          setViewList(viewList.push(...call.netmessage))
          // getPageParse(pageNum + 1, fileUrl, insuranceItem)
        } else {
          filePageParse(insuranceItem, selectItem, JSON.stringify(viewList))
        }
      }
    )
  }

  const getElsePageParse = (
    accountDate: string,
    list: any,
    resolve?: any,
    reject?: any
  ) => {
    dispatch(
      getAllopatryListAsync({
        accountDate,
        list,
      })
    )
      .then(unwrapResult)
      .then((res) => {
        if (res && JSON.stringify(res) !== '{}' && res.length) {
          setElseViewList(elseViewList.concat(res))
        }
        resolve && resolve()
      })
  }
  // 1812 异地对账
  const downLoadCheckItem = (
    serverType: any,
    accountData: string,
    loclDataVer?: number,
    responseString?: string,
    resolve?: any,
    reject?: any
  ) => {
    const insuranceItem = insuranceArray.find(
      (v) =>
        v.insuranceCode ===
        (queries.insuranceCode || insuranceArray[0].insuranceCode)
    )
    dispatch(
      elseWhereDownloadCheckAsync({
        serverType,
        body: {
          baseData: {
            ...insuranceItem,
            endTime: moment(insuranceItem?.endTime).format(
              DateTimeFormatSimpleOnlyOne
            ),
            userName,
            userId,
            cycleCode,
            tenantCategory,
            tenantAreaCd,
            name: tenantName,
            address: tenantAddress,
            areaCode: tenantAreaCd,
            responseString: responseString,
          },
          begndate: moment(accountData).startOf('day').format('YYYYMMDD'),
          enddate: moment(accountData).endOf('day').format('YYYYMMDD'),
          loclDataVer: loclDataVer || 0,
        },
      })
    )
      .then(unwrapResult)
      .then((res) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                insuranceItem?.countryHospitalNb,
                insuranceItem?.secretToken,
              ],
            },
            (call, err) => {
              if (!err) {
                const requestString = call.netmessage
                if (
                  requestString.output?.data &&
                  requestString.output?.data.length
                ) {
                  setViewList(viewList.push(...requestString.output.data))
                }
                downLoadCheckItem(
                  'response',
                  accountData,
                  loclDataVer || 0,
                  JSON.stringify(requestString),
                  resolve,
                  reject
                )
              } else {
                reject && reject()
              }
            },
            undefined,
            reject
          )
          // })
        } else {
          if (res?.continue) {
            downLoadCheckItem(
              'requestString',
              accountData,
              res?.loclDataVer || 0,
              undefined,
              resolve,
              reject
            )
          } else {
            getElsePageParse(accountData, viewList, resolve, reject)
          }
        }
      })
  }

  const downLoadCheck = (vs: any) => {
    let promiseList = []
    const count = moment().diff(moment(), 'days')
    message.loading('正在查询', 0)
    setShowStopAction(true)
    for (let index = 0; index <= count; index++) {
      const accountData = moment()
        .add(index, 'days')
        .format(DateTimeFormatSimpleOnlyOne)
      promiseList.push(
        new Promise((resolve, reject) => {
          downLoadCheckItem(
            'requestString',
            accountData,
            0,
            undefined,
            resolve,
            reject
          )
        })
      )
    }
    Promise.all(promiseList)
      .then(() => {
        onDownLoad(elseViewList, isLocal)
        setLoadState(true)
      })
      .catch((err) => {
        setTimeout(() => {
          setLoadState(true)
        }, 1000)
      })
      .finally(() => {
        setTimeout(() => {
          setLoadState(false)
          message.destroy()
          setShowStopAction(false)
        }, 1000)
      })
  }

  // 日对帐
  const resetSubmitItem = (
    vs: any,
    serverType: any,
    responseString?: string,
    resolve?: any,
    reject?: any,
    call?: any
  ) => {
    const insuranceItem = insuranceArray.find(
      (v) =>
        v.insuranceCode ===
        (queries.insuranceCode || insuranceArray[0].insuranceCode)
    )

    dispatch(
      accountCheckAsync({
        serverType,
        body: {
          baseData: {
            responseString: responseString ? responseString : null,
            ...insuranceItem,
            endTime: moment(insuranceItem.endTime).format(
              DateTimeFormatSimpleOnlyOne
            ),
            userName,
            userId,
            tenantCategory,
            tenantAreaCd,
            name: tenantName,
            address: tenantAddress,
            areaCode: tenantAreaCd,
          },
          insuranceCode: insuranceItem.insuranceCode,
          accountDate: moment(vs.accountDate).format(DateTimeFormatSimpleOnly),
          qingsuanlb: call ? call.input.data.clr_type : undefined,
          xianzhonglx: call ? call.input.data.insutype : undefined,
        },
      })
    )
      .then(unwrapResult)
      .then((res) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          data.map((v: any) => {
            medicalSendHttp(
              {
                trade_args: [
                  ...v,
                  insuranceItem.countryHospitalNb,
                  insuranceItem.secretToken,
                ],
              },
              (call) => {
                resetSubmitItem(
                  vs,
                  'response',
                  JSON.stringify(call.netmessage) +
                    '####' +
                    JSON.stringify(v[2]),
                  resolve,
                  reject,
                  v[2]
                )
              },
              undefined,
              reject
            )
          })
        } else {
          resolve && resolve()
          notification.success({
            message: '日对帐成功',
            duration: 2.5,
          })
          getPage()
        }
      })
  }

  const getPage = () => {
    setIsNew(true)
    const startTime = sessionStorage.getItem('startTime')
    const endTime = sessionStorage.getItem('endTime')
    dispatch(
      getAccountCheckListAsync({
        tenantId,
        insuranceCode: insuranceArray[0].insuranceCode,
        startDate: startTime
          ? moment(startTime).format(DateTimeFormatSimpleOnly)
          : moment().startOf('month').format(DateTimeFormatSimpleOnly),
        endDate: endTime
          ? moment(endTime).format(DateTimeFormatSimpleOnly)
          : moment().endOf('month').format(DateTimeFormatSimpleOnly),
        DZJG: '2',
        isLocal,
        ...queries,
      })
    )
      .then(unwrapResult)
      .then((res) => {
        setData(res)
      })
  }

  useEffect(() => {
    if (visible) {
      getPage()
    }
    return () => {
      if (visible) {
        setIsNew(false)
        setViewList([])
        setElseViewList([])
        setData({})
      }
    }
  }, [visible, queries, isLocal])

  useEffect(() => {
    return () => {
      if (visible) {
        setQueries({ current: 1, size: 10 })
      }
    }
  }, [visible])

  return (
    <>
      <Modal
        title='对账记录'
        visible={visible}
        onCancel={() => {
          sessionStorage.removeItem('startTime')
          sessionStorage.removeItem('endTime')
          onCancel && onCancel()
        }}
        width={1200}
        footer={null}
      >
        <div className={styles.accountList}>
          <RegisterQuery
            isLocal={isLocal}
            visible={isNew}
            loadingState={loadState}
            onValueChange={(vs) => {
              setQueries({ ...queries, ...vs })
            }}
            onSearch={(vs) => {
              downLoadCheck(vs)
              // setQueries({ ...queries, ...vs })
            }}
            onRegister={(insuranceCode) => {
              onReset(insuranceCode)
            }}
          />
          <Tabs
            defaultActiveKey='0'
            onChange={(v) => {
              setIsLocal(v)
            }}
          >
            <TabPane tab='本地' key={'0'}>
              <EditableList
                page={{
                  size: queries.size || 10,
                  current: queries.current || 1,
                  total: data?.total || 0,
                  items: data?.records || [],
                }}
                // columns={[]}
                columns={RegisterColumns(async (text, id, item) => {
                  const insuranceItem = insuranceArray.find(
                    (v: any) => v.insuranceCode === item.insuranceCode
                  )
                  if (text === 'detail') {
                    // onDetail()
                  } else if (text === 'download') {
                    updateFire(insuranceItem, item, 'requestString', '3202')
                  } else if (text === 'register') {
                    resetSubmitItem(item, 'requestString')
                  }
                }, (queries.size || 10) * ((queries.current || 1) - 1))}
                onChangePage={(current, size) => {
                  setQueries({
                    ...queries,
                    current,
                    size: size as number,
                  })
                }}
                rowSelection={
                  {
                    selectedRowKeys: selectIds,
                    ...rowSelection,
                  } as TableRowSelection<any>
                }
                onRow={(d: any) => ({
                  onDoubleClick: () => {
                    // onRecord(d)
                  },
                })}
                summary={() => (
                  <Table.Summary fixed>
                    <Table.Summary.Row className={styles.total}>
                      <Table.Summary.Cell index={0}></Table.Summary.Cell>
                      <Table.Summary.Cell index={1} align='center'>
                        合计
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={2}></Table.Summary.Cell>
                      <Table.Summary.Cell index={3}></Table.Summary.Cell>
                      <Table.Summary.Cell index={4}></Table.Summary.Cell>
                      <Table.Summary.Cell index={5}></Table.Summary.Cell>
                      <Table.Summary.Cell index={6} align='center'>
                        {data?.records?.[0]?.totalAllMoney}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={7} align='center'>
                        {data?.records?.[0]?.totalInsuranceMoney}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={8}></Table.Summary.Cell>
                      <Table.Summary.Cell index={9}></Table.Summary.Cell>
                      <Table.Summary.Cell index={10}></Table.Summary.Cell>
                    </Table.Summary.Row>
                  </Table.Summary>
                )}
              />
            </TabPane>
            <TabPane tab='异地' key={'1'}>
              <EditableList
                page={{
                  size: queries.size || 10,
                  current: queries.current || 1,
                  total: data?.total || 0,
                  items: data?.records || [],
                }}
                // columns={[]}
                columns={RegisterElseColumns(async (text, id, item) => {
                  // const insuranceItem = insuranceArray.find(
                  //   (v: any) => v.insuranceCode === item.insuranceCode
                  // )
                  // if (text === 'detail') {
                  //   // onDetail()
                  // } else if (text === 'download') {
                  //   updateFire(insuranceItem, item, 'requestString')
                  // }
                }, (queries.size || 10) * ((queries.current || 1) - 1))}
                onChangePage={(current, size) => {
                  setQueries({
                    ...queries,
                    current,
                    size: size as number,
                  })
                }}
                rowSelection={
                  {
                    selectedRowKeys: selectIds,
                    ...rowSelection,
                  } as TableRowSelection<any>
                }
                onRow={(d: any) => ({
                  onDoubleClick: () => {
                    // onRecord(d)
                  },
                })}
                summary={() => (
                  <Table.Summary fixed>
                    <Table.Summary.Row className={styles.total}>
                      <Table.Summary.Cell index={0}></Table.Summary.Cell>
                      <Table.Summary.Cell index={1} align='center'>
                        合计
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={2}></Table.Summary.Cell>
                      <Table.Summary.Cell index={3}></Table.Summary.Cell>
                      <Table.Summary.Cell index={4}></Table.Summary.Cell>
                      <Table.Summary.Cell index={5}></Table.Summary.Cell>
                      <Table.Summary.Cell index={6}></Table.Summary.Cell>
                      <Table.Summary.Cell index={7} align='center'>
                        {data?.records?.[0]?.totalAllMoney}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={8} align='center'>
                        {data?.records?.[0]?.totalInsuranceMoney}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={9}></Table.Summary.Cell>
                      <Table.Summary.Cell index={10}></Table.Summary.Cell>
                    </Table.Summary.Row>
                  </Table.Summary>
                )}
              />
            </TabPane>
          </Tabs>
        </div>
      </Modal>
      {showStopAction && (
        <div className={styles.stopAction}>
          正在查询...
          <br />
          请不要离开
        </div>
      )}
    </>
  )
}
