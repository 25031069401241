/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2022-07-07 11:49:52
 * @LastEditors: linxi
 * @LastEditTime: 2024-04-02 15:33:21
 */
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Radio,
  Row,
  Space,
  Spin,
  Tabs,
  Tooltip,
} from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../app/store'
import styles from './addFollowUp.module.css'
import { AddFollowUpQuery } from './addFollowUpQuery'
import { CommonRightForm } from './commonForm'
import { CurePlanListModal } from '../treatment/editor/modals/curePlanListModal'
import {
  getFollowUpWaitCancel,
  selectAddVisibleData,
} from './followUpPageSlice'
import { unwrapResult } from '@reduxjs/toolkit'
import { TreatmentBoxList } from '../treatment/list/TreatmentBoxList'
import { getTreatmentOldList } from '../treatment/list/treatmentSlice'
import { DateRangeType, getDateRange } from '../../compnents/form/DateSelect'
import { Dispose } from '../A-toothModule/patient/patientPanel/dispose/dispose'
import { DisposeItem } from '../A-toothModule/patient/patientPanel/dispose/disposeItem'
import {
  getFollowUpList,
  getFollowUpListDetail,
} from '../A-toothModule/toothHome/toothHomeSlice'
import { setCurePlanListVisible } from '../treatment/editor/recipeEditorSlice'
import { selectEdition } from '../../app/applicationSlice'
import { EditableList } from '../../compnents/list/EditableList'
import { Patient } from '../../models/patient'
import { getPatientList, selectPatientPage } from '../patient/list/patientListSlice'
import { getGenderName } from '../../models/user'
import { getAge } from '../../utils/StringUtils'
import { getSourceTypeName } from '../../models/registration'
import { Dimen } from '../../models/dimen'
import { GetPatientListParams } from '../../services/patient'
import { SearchOutlined } from '@ant-design/icons'
import { ThemeContext } from '../../theme/ThemeContext'
import { decrypt } from '../../models/m'

export const AddFollowUpModal = (props: {
  appointmentId?: string
  registrationId?: string
  status?: number //口腔专业版1 否则0
  tablePatientId?: string //HIS 口腔版患者 ID
  isModalVisible: boolean
  treatmentData?: any
  treatmentTag?: any //病例标志位
  id?: any //当前列表id
  bindType?: any
  bindId?: any
  state?: any //控制新增 随访计划 随访记录 待随访..
  onOk: () => void
  onCancel: () => void
}) => {
  // const { Group } = props.status == 1 ? Radio : Checkbox

  const { Group } = Radio

  const dispatch = useDispatch<RootDispatch>()

  const theme = useContext(ThemeContext)

  const [form] = Form.useForm()

  const [formCreate] = Form.useForm()

  const { TabPane } = Tabs

  const [selectKey, setSelectKey] = useState('0')

  const [loading, setLoading] = useState(false)

  const [head, tail] = getDateRange(DateRangeType.Today)

  const [leftQueryParam, setLeftQueryParam] = useState<any>()

  const [centerQueryParam, setCenterQueryParam] = useState<any>()

  const [queryParam, setQueryParam] = useState<any>()

  const [rightData, setRightData] = useState<any>()

  const [centerData, setCenterData] = useState<any>({})

  const [leftData, setLeftData] = useState<any>()

  const [treatmentLeftPage, setTreatmentLeftPage] = useState<any>([])

  const [name, setName] = useState<any>()

  const [treatmentId, setTreatmentId] = useState<any>()

  const [tcId, setTcId] = useState<any>()

  const [patientId, setPatientId] = useState<any>()

  const isAddVisibel: any = useSelector(selectAddVisibleData)

  const [dispositionList, setDispositionList] = useState<any>([]) //处置记录列表

  const [groupSelectKey, setGroupSelectKey] = useState<any>([])

  const [checkDataId, setCheckDataId] = useState<any>()

  const edition = useSelector(selectEdition)

  const page = useSelector(selectPatientPage)

  const [pageLoading, setPageLoading] = useState(false) // 表格 loading

  const [params, setParams] = useState<GetPatientListParams>({ current: 1 })

  const [selectedRows, setSelectedRows] = useState<any>([])

  const getPage = () => {
    setPageLoading(true)
    dispatch(
      getPatientList({
        ...params,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        // if (params.name?.length == 9 && res.items.length == 1) {
        //   navigateTo(res.items[0])
        // }
      })
      .finally(() => setPageLoading(false))
  }
  useEffect(() => {
    if(selectKey == '3') {
      getPage()
    } else {
      setSelectedRows([])
    }
  }, [dispatch, params, selectKey]);

  useEffect(() => {
    if (isAddVisibel == true) setSelectKey('0')
  }, [isAddVisibel])

  useEffect(() => {
    if (props?.isModalVisible == false) {
      setQueryParam({ ...queryParam, name: '', startTime: '', endTime: '' })
      setLeftQueryParam({
        ...leftQueryParam,
        name: '',
        startTime: '',
        endTime: '',
      })
    }
  }, [props?.isModalVisible])

  useEffect(() => {
    if (props?.isModalVisible)
      if (rightData && selectKey == '1') {
        setName(
          rightData?.[0]?.patientName || props?.treatmentData?.patientName
        )
        setTcId(rightData?.[0]?.id)
        setPatientId(rightData?.[0]?.treatmentTpsDetail?.[0]?.patientId)
      } else if (selectKey == '2') {
        setGroupSelectKey([])
        setCheckDataId(undefined)
      }
  }, [rightData, selectKey, props?.isModalVisible, props?.treatmentData])

  const getDispositionList = (value: any) => {
    dispatch(
      getFollowUpList({
        keyWord: value?.name,
        startTime: props.status ? undefined : value?.startTime || head,
        endTime: props.status ? undefined : value?.endTime || tail,
        patientId: props.tablePatientId || '',
        id: props.bindId, //执行详情 ID,
      })
    )
      .then(unwrapResult)
      .then((res) => {
        if (props.bindType == 2) {
          getDispositionListTable(res[0]?.recipeId, res)
        } else {
          setDispositionList(
            res.map((v: any) => {
              return {
                ...v,
                open: false,
                materials: [],
              }
            })
          )
        }
      })
  }
  const getDispositionListTable = (id: string, obj?: any) => {
    //获取处置记录列表表格
    dispatch(getFollowUpListDetail({ recipeId: id }))
      .then(unwrapResult)
      .then((res) => {
        setDispositionList(
          (obj ? obj : dispositionList).map((v: any) => {
            return {
              ...v,
              open:
                props?.bindType == 2
                  ? true
                  : id == v?.recipeId
                  ? !v.open
                  : v.open,
              materials: id == v.recipeId ? res : v.materials,
            }
          })
        )
      })
  }

  useEffect(() => {
    if (
      (props.isModalVisible && selectKey == '2') ||
      (props.isModalVisible && props?.bindType == '2')
    ) {
      getDispositionList(centerQueryParam)
    }
  }, [props.isModalVisible, selectKey, centerQueryParam])

  useEffect(() => {
    if (props?.isModalVisible)
      if (leftData && selectKey == '0') {
        setName(
          leftData?.[0]
            ? leftData?.[0]?.patientName
            : props?.treatmentData?.patientName
        )
        setTreatmentId(leftData?.[0] ? leftData?.[0]?.id : '')
        setPatientId(leftData?.[0] ? leftData?.[0]?.patientId : '')
      }
  }, [leftData, selectKey, props?.isModalVisible])

  useEffect(() => {
    // 执行进入条件
    if (
      selectKey == '0' &&
      props?.isModalVisible == true &&
      props?.bindType == 0 &&
      props?.id &&
      props?.treatmentTag != true
    ) {
      setLoading(true)
      dispatch(
        getTreatmentOldList({
          param: leftQueryParam?.name,
          size: 500,
          current: 1,
          id: props?.bindId,
        })
      )
        .then(unwrapResult)
        .then((res: any) => {
          setTreatmentLeftPage(res)
          setLoading(false)
        })
    } else if (
      selectKey == '0' &&
      props?.isModalVisible == true &&
      props?.bindType != 1 &&
      props?.treatmentTag !== true
    ) {
      dispatch(
        getTreatmentOldList({
          param: leftQueryParam?.name,
          size: 500,
          current: 1,
          treatmentTimeHead: props.status
            ? undefined
            : leftQueryParam?.startTime || head,
          treatmentTimeTail: props.status
            ? undefined
            : leftQueryParam?.endTime || tail,
          patientId: props.tablePatientId,
          // bindId:
        })
      )
        .then(unwrapResult)
        .then((res: any) => {
          setTreatmentLeftPage(res)
          setLoading(false)
        })
    }
  }, [
    leftQueryParam,
    props?.isModalVisible,
    selectKey,
    props?.id,
    props?.bindType,
    props?.bindId,
    props?.treatmentData,
    props?.treatmentTag,
  ])

  useEffect(() => {
    setSelectKey('0')
  }, [props?.isModalVisible])

  useEffect(() => {
    //病例进入
    if (
      selectKey == '0' &&
      props?.isModalVisible == true &&
      props?.treatmentTag == true &&
      props?.treatmentData?.patientId &&
      props?.treatmentData?.id
    ) {
      dispatch(
        getTreatmentOldList({
          param: leftQueryParam?.name,
          size: 500,
          current: 1,
          patientId: props?.treatmentData?.patientId || props.tablePatientId,
          id: props?.treatmentData?.id,
        })
      )
        .then(unwrapResult)
        .then((res: any) => {
          setTreatmentLeftPage(res)
          setLoading(false)
        })
    }
  }, [
    selectKey,
    props?.isModalVisible,
    props?.treatmentTag,
    props?.treatmentData,
  ])

  return (
    <Modal
      className={styles.addModal}
      width={1300}
      title={props?.id ? '随访执行' : '新增随访'}
      visible={props.isModalVisible}
      maskClosable={false}
      onOk={() => {
        props.onOk()
        form.resetFields()
        setDispositionList([])
      }}
      onCancel={() => {
        props.onCancel()
        form.resetFields()
        setDispositionList([])
      }}
      footer={null}
    >
      <Form
        // {...layout}
        form={form}
        onFinish={(fs) => {
          return
        }}
      >
        <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Col span={16}>
            {/* 点击执行时的页面 */}
            {props?.id ? (
              <Row
                style={{
                  height: '556px',
                  position: 'relative',
                }}
              >
                <Row style={{ color: '#101010', width: '100%' }}>
                  {props?.bindType == '0'
                    ? '就诊记录'
                    : props?.bindType == '1'
                    ? '治疗计划'
                    : '处置记录'}
                </Row>
                <Spin
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    zIndex: 99,
                  }}
                  spinning={loading}
                  indicator={undefined}
                  tip=''
                ></Spin>
                {props?.bindType == '0' ? (
                  <div
                    style={{
                      height: '500px',
                      overflow: 'auto',
                      width: '100%',
                    }}
                  >
                    <TreatmentBoxList
                      tag={true} //执行默认展开
                      page={treatmentLeftPage && treatmentLeftPage}
                      type={undefined}
                      onOk={(v) => {
                        return
                      }}
                    />
                  </div>
                ) : props?.bindType == '1' ? (
                  <div
                    style={{
                      height: '590px',
                      overflow: 'auto',
                      width: '100%',
                    }}
                  >
                    <CurePlanListModal
                      tag={true} //控制展开
                      showName={false} //控制按钮
                      bindId={props?.bindId}
                      selectKeys={selectKey}
                      treatmentData={props?.treatmentData} //口腔病例增加时所需要的id以及name
                      type={'0'}
                      treatmentId={treatmentId || ''}
                      patientId={patientId || ''}
                      queryParam={queryParam}
                      onOk={(v) => {
                        setRightData(v)
                      }}
                      onLoad={(v) => {
                        setLoading(v)
                      }}
                    />
                  </div>
                ) : props?.bindType == '2' ? (
                  <div
                    style={{
                      height: '495px',
                      width: '100%',
                      overflow: 'auto',
                    }}
                  >
                    {dispositionList.map((v: any) => (
                      <DisposeItem
                        tag={true}
                        ListItem={v}
                        status={1}
                        bindType={props.bindType}
                        key={v.recipeId}
                      />
                    ))}
                  </div>
                ) : (
                  ''
                )}
              </Row>
            ) : (
              <Tabs
                activeKey={selectKey}
                defaultActiveKey='0'
                onChange={(v: any) => {
                  setSelectKey(v)
                }}
              >
                <TabPane
                  tab={props.status == 1 ? '病历信息' : '就诊记录'}
                  key='0'
                >
                  <div
                    style={{
                      height: '495px',
                      // overflow: 'auto',
                    }}
                  >
                    <>
                      {/* 病例点击没有查询条件以及治疗计划*/}
                      {!props?.treatmentTag && (
                        <>
                          <Row>
                            <i style={{ color: '#9A9A9A' }}>
                              请选择一个
                              {props.status == 1 ? '病历信息' : '就诊记录'}
                              {edition == 1 ? '或处置记录' : ''}或治疗计划
                            </i>
                          </Row>
                          {props.status != 1 && (
                            <Row>
                              <AddFollowUpQuery
                                visibel={props?.isModalVisible}
                                onValueChange={(v) => {
                                  setLeftQueryParam({
                                    ...v,
                                    startTime: v?.startTime || head,
                                    endTime: v?.endTime || tail,
                                  })
                                }}
                              ></AddFollowUpQuery>
                            </Row>
                          )}
                        </>
                      )}
                      <div
                        style={{
                          height: '424px',
                          overflow: 'auto',
                          position: 'relative',
                        }}
                      >
                        <Spin
                          style={{
                            position: 'absolute',
                            top: '36%',
                            left: '50%',
                            zIndex: 99,
                          }}
                          spinning={loading}
                          indicator={undefined}
                          tip=''
                        ></Spin>
                        <TreatmentBoxList
                          status={props?.status}
                          tag={!!props?.treatmentTag} //病例默认展开，新增不展开
                          treatmentData={props?.treatmentData}
                          page={treatmentLeftPage && treatmentLeftPage}
                          type='radio'
                          onOk={(v) => {
                            setLeftData(v)
                          }}
                        />
                      </div>
                    </>
                  </div>
                </TabPane>
                {edition == 1 && (
                  <TabPane tab='处置记录' key='2'>
                    <div
                      style={{
                        height: '495px',
                        overflow: 'auto',
                      }}
                    >
                      <Row>
                        <i style={{ color: '#9A9A9A' }}>
                          请选择一个
                          {props.status == 1 ? '病历信息' : '就诊记录'}
                          {edition == 1 ? '或处置记录' : ''}或治疗计划
                        </i>
                      </Row>
                      {props.status != 1 && (
                        <Row>
                          <AddFollowUpQuery
                            selectKey={selectKey}
                            visibel={props?.isModalVisible}
                            onValueChange={(v) => {
                              setCenterQueryParam({
                                ...v,
                                startTime: v?.startTime || head,
                                endTime: v?.endTime || tail,
                              })
                            }}
                          ></AddFollowUpQuery>
                        </Row>
                      )}
                      <Group
                        className={styles.container}
                        onChange={(v: any) => {
                          if (props.status == 1 || props.status == 0) {
                            //单选
                            setGroupSelectKey(v?.target.value)
                          } else {
                            setCheckDataId(v?.target.value)
                          }
                          const tempObj = dispositionList.find(
                            (i: any) => i.recipeId == v?.target?.value
                          )
                          setCenterData(tempObj)
                          setName(tempObj.patientName)
                          setPatientId(tempObj?.patientId)
                        }}
                        // value={props.status == 1 ? groupSelectKey : checkDataId}
                        value={groupSelectKey}
                      >
                        {dispositionList.map((v: any) => (
                          <DisposeItem
                            ListItem={v}
                            status={1}
                            key={v.recipeId}
                            onAction={(action, id) => {
                              setGroupSelectKey(id?.recipeId)
                              setCenterData(id)
                              setName(id?.patientName)
                              setPatientId(id?.patientId)
                              if (action == 'open') {
                                getDispositionListTable(id?.recipeId)
                              }
                            }}
                          />
                        ))}
                      </Group>
                    </div>
                  </TabPane>
                )}
                {!props?.treatmentTag && (
                  <TabPane tab='治疗计划' key='1'>
                    <div
                      style={{
                        height: '495px',
                        // overflow: 'auto',
                      }}
                    >
                      <Row>
                        <i style={{ color: '#9A9A9A' }}>
                          请选择一个
                          {props.status == 1 ? '病历信息' : '就诊记录'}
                          {edition == 1 ? '或处置记录' : ''}或治疗计划
                        </i>
                      </Row>
                      {props.status != 1 && (
                        <Row>
                          <AddFollowUpQuery
                            selectKey={selectKey}
                            visibel={props?.isModalVisible}
                            onValueChange={(v) => {
                              setQueryParam({
                                ...v,
                                startTime: v?.startTime || head,
                                endTime: v?.endTime || tail,
                              })
                            }}
                          ></AddFollowUpQuery>
                        </Row>
                      )}
                      <div
                        style={{
                          height: '475px',
                          overflow: 'auto',
                          position: 'relative',
                        }}
                      >
                        <Spin
                          style={{
                            position: 'absolute',
                            top: '36%',
                            left: '50%',
                            zIndex: 99,
                          }}
                          spinning={loading}
                          indicator={undefined}
                          tip=''
                        ></Spin>
                        <CurePlanListModal
                          sourceType={0}
                          statusType={props.status}
                          noTime={!props.status} //控制时间查询
                          selectKeys={selectKey}
                          treatmentData={props?.treatmentData} //口腔病例增加时所需要的id以及name
                          type='radio'
                          treatmentId={treatmentId || ''}
                          patientId={props.tablePatientId || ''}
                          queryParam={queryParam}
                          onOk={(v) => {
                            setRightData(v)
                          }}
                          onLoad={(v) => {
                            setLoading(v)
                          }}
                        />
                      </div>
                    </div>
                  </TabPane>
                )}
                <TabPane tab='患者列表' key='3'>
                    <div
                      style={{
                        height: '495px',
                        overflow: 'hidden',
                      }}
                    >
                      <Row style={{marginBottom: 15}}>
                        <Form
                          form={formCreate}
                          autoComplete='off'
                          onFinish={(values) => {
                            setParams({
                              ...params,
                              ...values,
                            })
                          }}
                          style={{width: '98%'}}
                        >
                          <Row align='middle' justify='space-between'>
                            <Space>
                              <Form.Item name='name' noStyle>
                                <Input
                                  // ref={inputRef}
                                  allowClear
                                  style={{ width: 315 }}
                                  onChange={formCreate.submit}
                                  placeholder='请输入姓名/手机号/助记码/病历号'
                                  prefix={<SearchOutlined style={{ color: theme.tc3 }} />}
                                />
                              </Form.Item>
                            </Space>
                            <Space>
                              <Button type='primary' htmlType='submit'>
                                查询
                              </Button>
                            </Space>
                          </Row>
                        </Form>
                      </Row>
                        <EditableList<Patient>
                          style={{ height: '400px' }}
                          loading={pageLoading}
                          page={page}
                          rowSelection={{
                            type: 'radio',
                            selectedRowKeys: selectedRows,
                            onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {
                              setSelectedRows(selectedRowKeys)
                              setName(selectedRows[0]?.name)
                              setPatientId(selectedRowKeys[0])
                              setTreatmentId(selectedRowKeys[0])
                            }
                          }}
                          columns={[
                            {
                              title: '序号',
                              key: 'no',
                              width: Dimen.Num,
                              align: 'center',
                              render: (_1, _2, i) => `${page.size * (page.current - 1) + i + 1}`,
                            },
                            {
                              title: '患者姓名',
                              dataIndex: 'name',
                              width: Dimen.Name,
                              align: 'center',
                            },
                            {
                              title: '病历号',
                              key: 'id',
                              width: Dimen.Sex,
                              align: 'center',
                              dataIndex: 'id',
                            },
                            {
                              title: '性别',
                              dataIndex: 'sex',
                              align: 'center',
                              render: function ShowActions(_, p) {
                                return getGenderName(p.sex)
                              },
                            },
                            {
                              title: '年龄',
                              dataIndex: 'ageYear',
                              align: 'center',
                              render: (_, r) => getAge(r?.ageYear, r?.ageMonth),
                            },
                            {
                              title: '身份证',
                              dataIndex: 'idCardNumber',
                              width: Dimen.IdCardNumber,
                              align: 'center',
                            },
                            {
                              title: '联系电话',
                              dataIndex: 'phone',
                              width: Dimen.TelPhone,
                              align: 'center',
                              render: (_, r) => {
                                return r?.phone ? decrypt(r?.phone) : '-'
                              },
                            },
                            {
                              title: '患者来源',
                              dataIndex: 'source',
                              align: 'center',
                              render: function ShowActions(_, p) {
                                return getSourceTypeName(Number(p.source || 0))
                              },
                            },
                            {
                              title: '标签',
                              dataIndex: 'labelVo',
                              width: Dimen.Tag,
                              align: 'center',
                              render: function ShowActions(_, p) {
                                return (
                                  <Space>
                                    {!!p.labelVo.length &&
                                      p.labelVo.map((v: any) => {
                                        return (
                                          <Tooltip key={v.id} title={v.name}>
                                            <span
                                              style={{
                                                color: v.fontColor,
                                                backgroundColor: v.backgroundColor,
                                                cursor: 'pointer',
                                                padding: '2px 5px',
                                              }}
                                            >
                                              {v.headName}
                                            </span>
                                          </Tooltip>
                                        )
                                      })}
                                  </Space>
                                )
                              },
                            },
                          ]}
                          onChangePage={(current, size) =>
                            setParams({ ...params, current, size })
                          }
                        />
                    </div>
                  </TabPane>
              </Tabs>
            )}
            {/* 待随访展示 */}
            {props?.id && (
              <Row>
                <Button
                  type='primary'
                  danger
                  ghost
                  onClick={() => {
                    Modal.confirm({
                      content: '请确认是否取消随访？',
                      onOk: async () => {
                        await dispatch(getFollowUpWaitCancel(props?.id))
                          .then(unwrapResult)
                          .then(() => {
                            notification.success({
                              message: '操作成功',
                              duration: 3,
                            })
                            props.onOk()
                            form.resetFields()
                          })
                      },
                      onCancel: () => {
                        props?.onCancel()
                      },
                    })
                  }}
                  // htmlType='submit'
                >
                  取消随访
                </Button>
              </Row>
            )}
          </Col>

          <Col span={8}>
            <CommonRightForm
              status={props.status}
              treatmentData={props?.treatmentData}
              name={name}
              tcId={tcId}
              treatmentId={treatmentId}
              recipeId={centerData.recipeId}
              appointmentId={props.appointmentId}
              registrationId={props.registrationId}
              paytientId={patientId ? patientId : props.tablePatientId}
              id={props?.id}
              selectKey={selectKey}
              onValueChange={(v: any) => {
                return
              }}
              onCancel={() => {
                props.onCancel()
                form.resetFields()
                setTreatmentLeftPage([])
              }}
              onOk={() => {
                props.onOk()
                form.resetFields()
                setTreatmentLeftPage([])
              }}
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
